import React, { useMemo } from "react";
import PropTypes from "prop-types";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import getTheme from "./getTheme";

export default function SiteThemeProvider(props) {
  const theme = useMemo(() => createTheme(getTheme("light")), []);
  return (
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  );
}

SiteThemeProvider.propTypes = {
  children: PropTypes.node,
};
