import React from "react";
import PropTypes from "prop-types";
import { Highlight } from "prism-react-renderer";
import { styled } from "@mui/material/styles";
import Copy from "./copy";
import normalize from "./normalize";
import { randomId } from "../../../utils/index";

const Root = styled("div")(({ theme }) => ({
  position: "relative",
  marginBottom: theme.spacing(2),
}));

const Pre = styled("pre")(() => ({
  overflowX: "auto",
  padding: "1.25em 1.5em",
  whiteSpace: "pre",
  wordWrap: "normal",
  backgroundColor: "whitesmoke",
  color: "#4a4a4a",
  fontSize: "0.875em",
}));

const getParams = (name = "") => {
  const [lang, params = ""] = name.split(":");
  return [lang.split("language-").pop().split("{").shift()].concat(
    params.split("&").reduce((merged, param) => {
      const [key, value] = param.split("=");
      merged[key] = value;
      return merged;
    }, {}),
  );
};

/*
 * MDX passes the code block as JSX
 * we un-wind it a bit to get the string content
 * but keep it extensible so it can be used with just children (string) and className
 */
const CodeBlock = ({
  children,
  className = children.props ? children.props.className : "",
  copy,
}) => {
  const [language, { title = "" }] = getParams(className);
  const [content, highlights] = normalize(
    children.props && children.props.children
      ? children.props.children
      : children,
    className,
  );

  return (
    <Highlight code={content} language={language} theme={undefined}>
      {({ tokens, getLineProps, getTokenProps }) => (
        <React.Fragment>
          <Root>
            <Pre className={`language-${language}`}>
              {copy && <Copy fileName={title} content={content} />}
              <code className={`language-${language}`}>
                {tokens.map((line, i) => {
                  const lineProps = getLineProps({
                    line,
                    key: `code_block_z_${randomId()}${i}`,
                  });
                  const className = [lineProps.className]
                    .concat(highlights[i] && "gatsby-highlight-code-line")
                    .filter(Boolean)
                    .join(" ");
                  return (
                    <div
                      key={`code_block_x_${randomId()}${i}`}
                      {...{ ...lineProps, className }}
                    >
                      {line.map((token, key) => {
                        const keyy = `code_block_y_${randomId()}${key}`;
                        const keyz = `code_block_z_${randomId()}${key}`;
                        return (
                          <span
                            key={keyy}
                            {...getTokenProps({ token, keyz })}
                          />
                        );
                      })}
                    </div>
                  );
                })}
              </code>
            </Pre>
          </Root>
        </React.Fragment>
      )}
    </Highlight>
  );
};

CodeBlock.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.string,
  copy: PropTypes.bool,
};

CodeBlock.defaultProps = {
  copy: true,
};

export default CodeBlock;
