import { green, red } from "@mui/material/colors";
import primary from "./colors/primary";
import secondary from "./colors/secondary";
import getComponents from "./getComponents";

const getTheme = (mode, isApp = false) => ({
  components: getComponents(mode),
  palette: {
    mode,
    primary: {
      ...(mode === "light"
        ? {
            light: primary[500],
            main: primary[700],
            dark: primary[900],
          }
        : {
            light: primary[100],
            main: primary[200],
            dark: primary[300],
          }),
    },
    secondary: {
      ...(mode === "light"
        ? {
            light: secondary[400],
            main: secondary[600],
            dark: secondary[800],
          }
        : {
            light: secondary[100],
            main: secondary[200],
            dark: secondary[300],
          }),
    },
    success: {
      ...(mode === "light"
        ? {
            light: green[400],
            main: green[500],
            dark: green[700],
          }
        : {
            light: green[100],
            main: green[200],
            dark: green[300],
          }),
    },
    error: {
      ...(mode === "light"
        ? {
            light: red[400],
            main: red[500],
            dark: red[700],
          }
        : {
            light: red[100],
            main: red[200],
            dark: red[300],
          }),
    },
    text: {
      ...(isApp
        ? {}
        : {
            primary: "#212353",
          }),
    },
    background: {
      ...(isApp
        ? {
            paper: mode === "dark" ? "#303030" : "#fff",
            default: mode === "dark" ? "#303030" : "#fafafa",
          }
        : {
            paper: "#fff",
            default: "#fff",
          }),
    },
  },
  typography: {
    fontFamily: "'Montserrat', sans-serif",
    fontSize: isApp ? 14 : 16,
    textTransform: "none",
  },
});

export default getTheme;
