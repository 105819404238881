import { wrapRootElement as wrap } from './wrap-root-element';

export const wrapRootElement = wrap;

export const onServiceWorkerUpdateReady = () => {
  if (
    window.confirm(
      'This site has been updated. Do you wish to reload the site to get the new version?',
    )
  ) {
    window.location.reload(true);
  }
};
