const primary = {
  50: "#F4E4F5",
  100: "#E4BCE6",
  200: "#D38FD6",
  300: "#C161C6",
  400: "#B33BBA",
  500: "#A507AE",
  600: "#9705A8",
  700: "#8300A1",
  800: "#72009A",
  900: "#52008E",
  A100: "#ea80fc",
  A200: "#e040fb",
  A400: "#d500f9",
  A700: "#aa00ff",
};

export default primary;
