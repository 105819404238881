import React from 'react';
import PropTypes from 'prop-types';
import { MDXProvider } from '@mdx-js/react';
import components from './src/components/mdx/components';
import 'typeface-montserrat';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'animate.css/animate.min.css';
import './src/styles/all.sass';

export const wrapRootElement = ({ element }) => (
  <MDXProvider components={components}>{element}</MDXProvider>
);

wrapRootElement.propTypes = {
  element: PropTypes.element,
};
