import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import copyToClipboard from "../../../utils/copy-to-clipboard";

const delay = (duration) =>
  new Promise((resolve) => setTimeout(resolve, duration));

function Copy({ content, duration, fileName, trim = false }) {
  const [copied, setCopied] = useState(false);

  const label = copied
    ? `${fileName ? `${fileName} ` : ""}copied to clipboard`
    : `${fileName ? `${fileName}: ` : ""}copy code to clipboard`;

  return (
    <Button
      name={label}
      sx={() => ({
        color: "inherit",
        position: "absolute",
        top: "0.25rem",
        right: "0.25rem",
      })}
      disabled={copied}
      onClick={async () => {
        await copyToClipboard(trim ? content.trim() : content);

        setCopied(true);

        await delay(duration);

        setCopied(false);
      }}
    >
      {copied ? "Copied" : "Copy"}
    </Button>
  );
}

Copy.propTypes = {
  content: PropTypes.string.isRequired,
  duration: PropTypes.number,
  trim: PropTypes.bool,
  fileName: PropTypes.string,
};

Copy.defaultProps = {
  duration: 5000,
  fileName: "",
};

export default Copy;
